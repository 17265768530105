import React from 'react';
import { navigate } from 'gatsby';
import Img from 'gatsby-image';
import captions from '../captions';

class ImagePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideCaption: false
        };
        this.elementRef = React.createRef();
    }

    componentDidMount() {
        this.elementRef.current.focus();
    }

    handleKeyPress = event => {
        switch (event.key) {
            case 'ArrowRight':
                if (this.props.index < this.props.images.edges.length - 1) {
                    this.props.changeIndex(this.props.index + 1);
                }
                break;
            case 'ArrowLeft':
                if (this.props.index > 0) {
                    this.props.changeIndex(this.props.index - 1);
                }
                break;
            default:
                break;
        }
    };

    render() {
        const { images, title, index, close, changeIndex } = this.props;
        const imageFluid = images.edges[index].node.childImageSharp.fluid;
        const nameExtract = imageFluid.src.split('/');
        const imageName = nameExtract[nameExtract.length - 1].split('.')[0];
        const caption = captions[title][imageName];
        if (!caption.title && !this.state.hideCaption) {
            this.setState({
                hideCaption: true
            });
        }

        return (
            <div
                className="gallery-preview"
                onKeyDown={this.handleKeyPress}
                ref={this.elementRef}
                tabIndex="0"
            >
                <div
                    className="gallery-controls"
                    onClick={() => {
                        if (index < images.edges.length - 1) {
                            changeIndex(index + 1);
                        }
                    }}
                >
                    <i
                        className="fa fa-times close-preview"
                        onClick={e => {
                            e.stopPropagation();
                            close();
                        }}
                    ></i>
                    <i
                        title="Previous"
                        className={[
                            'fa',
                            'fa-angle-left',
                            'prev-preview',
                            index > 0 ? '' : 'hidden'
                        ].join(' ')}
                        onClick={e => {
                            e.stopPropagation();
                            changeIndex(index - 1);
                        }}
                    ></i>
                    <div className="right-controls">
                        <i
                            title="Thumbnails"
                            className={[
                                'fa',
                                'fa-picture-o',
                                'thumbnails'
                            ].join(' ')}
                            onClick={e => {
                                e.stopPropagation();
                                close();
                            }}
                        ></i>
                        <i
                            title="Next"
                            className={[
                                'fa',
                                'fa-angle-right',
                                'next-preview',
                                index < images.edges.length - 1 ? '' : 'hidden'
                            ].join(' ')}
                            onClick={e => {
                                e.stopPropagation();
                                changeIndex(index + 1);
                            }}
                        ></i>
                        <i
                            title="Gallery"
                            className={['fa', 'fa-th', 'gallery'].join(' ')}
                            onClick={e => {
                                e.stopPropagation();
                                close();
                                navigate('/');
                            }}
                        ></i>
                    </div>
                    <i
                        className={[
                            'fa',
                            'fa-info-circle',
                            'show-caption',
                            this.state.hideCaption && caption.title
                                ? ''
                                : 'hidden'
                        ].join(' ')}
                        onClick={e => {
                            e.stopPropagation();
                            this.setState({
                                hideCaption: false
                            });
                        }}
                    ></i>
                </div>
                <div
                    className={[
                        'gallery-caption',
                        this.state.hideCaption ? 'hidden' : ''
                    ]
                        .join(' ')
                        .trim()}
                >
                    <i
                        className="fa fa-times close-caption"
                        onClick={e => {
                            e.stopPropagation();
                            this.setState({
                                hideCaption: true
                            });
                        }}
                    ></i>
                    <div className="caption-title">{caption.title}</div>
                    <div className="caption-desc">
                        {caption.description.startsWith('http://') ||
                        caption.description.startsWith('https://') ? (
                            <a
                                href={caption.description}
                                target="_blank"
                                rel="noopener noreferrer nofollow"
                            >
                                {caption.description}
                            </a>
                        ) : (
                            caption.description
                        )}
                    </div>
                </div>
                <Img
                    fluid={{
                        ...imageFluid,
                        sizes: `100vw`
                    }}
                    style={{ maxHeight: '100%' }}
                    imgStyle={{ objectFit: 'contain' }}
                />
            </div>
        );
    }
}

export default ImagePreview;
