import { graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import '../components/content.css';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ImagePreview from '../components/ImagePreview';

class Template extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showImagePreview: false,
            imageIndex: 0
        };
    }

    showImage = index => {
        this.setState({
            showImagePreview: true,
            imageIndex: index
        });
    };

    hideImage = () => {
        this.setState({
            showImagePreview: false
        });
    };

    render() {
        const { frontmatter, html } = this.props.data.markdown;
        const images = [];
        let imageCounter = 0;
        if (frontmatter.galleryRowLayout) {
            const galleryRows = frontmatter.galleryRowLayout.split(',');
            for (const rowArrangement of galleryRows) {
                const howManyInARow = +rowArrangement;
                const localRow = [];
                for (let i = 0; i < howManyInARow; i++) {
                    localRow.push(
                        <div
                            key={`thumbnail${imageCounter}`}
                            onClick={this.showImage.bind(this, imageCounter)}
                        >
                            <Img
                                fluid={{
                                    ...this.props.data.images.edges[
                                        imageCounter
                                    ].node.childImageSharp.fluid,
                                    sizes: `${Math.round(
                                        100 / howManyInARow
                                    )}vw`
                                }}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    );
                    imageCounter++;
                }
                images.push(
                    <div
                        key={`rowAt${imageCounter}`}
                        className={`gallery-row-${rowArrangement}`}
                    >
                        {localRow}
                    </div>
                );
            }
        }
        return (
            <Layout active={frontmatter.parent}>
                <SEO
                    title={frontmatter.title}
                    description={frontmatter.description}
                />
                <div
                    className={[
                        'page',
                        this.state.showImagePreview ? 'noscroll' : ''
                    ]
                        .join(' ')
                        .trim()}
                >
                    {frontmatter.description && (
                        <>
                            <div className="gallery-info">
                                <div className="gallery-title">
                                    {frontmatter.title}
                                </div>
                                <div className="gallery-description">
                                    {frontmatter.description}
                                </div>
                            </div>
                            <div className="page-gallery">{images}</div>
                        </>
                    )}
                    {frontmatter.galleryRowLayout && (
                        <div className="bottom-controls">
                            <span
                                className={[
                                    'next-page',
                                    frontmatter.nav ? 'visible' : ''
                                ]
                                    .join(' ')
                                    .trim()}
                                onClick={() => {
                                    if (frontmatter.nav) {
                                        navigate(frontmatter.nav);
                                    }
                                }}
                            >
                                {' '}
                                More &nbsp; &nbsp; &nbsp; &nbsp;
                            </span>
                            <span
                                className="back-to-top"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                }}
                            >
                                {' '}
                                Back to top
                            </span>
                            <span
                                className="back-to-gallery"
                                onClick={() => {
                                    navigate('/');
                                }}
                            >
                                {' '}
                                Gallery <i class="fa fa-th"></i>
                            </span>
                        </div>
                    )}
                    {this.state.showImagePreview && (
                        <ImagePreview
                            images={this.props.data.images}
                            title={frontmatter.title}
                            index={this.state.imageIndex}
                            close={this.hideImage}
                            changeIndex={this.showImage}
                        />
                    )}
                    <div
                        className="page-content"
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </div>
            </Layout>
        );
    }
}

export default Template;

export const pageQuery = graphql`
    query($path: String!, $title: String!) {
        markdown: markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                title
                description
                parent
                galleryRowLayout
                nav
            }
        }
        images: allFile(
            sort: { fields: name }
            filter: { relativeDirectory: { eq: $title } }
        ) {
            edges {
                node {
                    id
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
